import { useMemo, useCallback, useEffect } from "react";

import {
    MainContainer, Sidebar, ConversationList,
    Conversation, Avatar, ChatContainer, ConversationHeader, MessageGroup, Message, MessageList, MessageInput, TypingIndicator
} from "@chatscope/chat-ui-kit-react";

import {
    useChat,
    ChatMessage,
    MessageContentType,
    MessageDirection,
    MessageStatus,
    ConversationId
} from "@chatscope/use-chat";
import { MessageContent, TextContent, User } from "@chatscope/use-chat";

import { Configuration, OpenAIApi } from 'openai'
import { addConversation, getLastConversation, setLastConversation } from "../storage/LocalStorageHelper";

const queryParameters = new URLSearchParams(window.location.search)

const configuration = new Configuration({
    apiKey: queryParameters.get("key")?.valueOf()
})

const openai = new OpenAIApi(configuration)

var generatingResponse = false;

export const Chat = ({ user }: { user: User }) => {

    // Get all chat related values and methods from useChat hook 
    const {
        currentMessages, conversations, activeConversation, setActiveConversation, sendMessage, getUser, currentMessage, setCurrentMessage, setCurrentUser
    } = useChat();


    useEffect(() => {
        setCurrentUser(user);
        setActiveConversation(getLastConversation());
    }, [user, setCurrentUser, setActiveConversation]);

    const putActiveConversation = (id: string) => {
        setLastConversation(id);
        setActiveConversation(id);
    }


    // Get current user data
    const [aiUserAvatar, aiUserName, aiUserBio] = useMemo(() => {

        if (activeConversation) {
            const participant = activeConversation.participants.length > 0 ? activeConversation.participants[0] : undefined;

            if (participant) {
                const aiUser = getUser(participant.id);
                if (aiUser) {
                    return [<Avatar src={aiUser.avatar} />, aiUser.username, aiUser.bio]
                }
            }
        }

        return [undefined, undefined, undefined];

    }, [activeConversation, getUser]);

    const handleChange = (value: string) => {
        setCurrentMessage(value);
    }

    const addMessage = (text: string, userID: string, aiUserID: string, conversationID: ConversationId, messageDirection: MessageDirection) => {
        const message = new ChatMessage({
            id: "", // Id will be generated by storage generator, so here you can pass an empty string
            content: text as unknown as MessageContent<TextContent>,
            contentType: MessageContentType.TextPlain,
            senderId: userID,
            direction: messageDirection,
            status: MessageStatus.Sent
        });

        sendMessage({
            message,
            conversationId: conversationID,
            senderId: userID,
        });

        addConversation(aiUserID, message)
    }

    const handleSend = async (text: string) => {


        if (activeConversation && activeConversation.participants.length > 0) {
            const participant = activeConversation.participants[0]
            const aiUser = getUser(participant.id);

            if (!aiUser)
                return

            addMessage(text, user.id, aiUser.id, activeConversation.id, MessageDirection.Outgoing);

            generatingResponse = true;

            const payload = {
                model: 'gpt-3.5-turbo-instruct',
                prompt: aiUser.id === "Bot" ? text : `${aiUser.firstName}:'${text}'`,
                temperature: process.env.AI_TEMP ? parseFloat(process.env.AI_TEMP) : 0.9,
                max_tokens: process.env.AI_MAX_TOKENS ? parseInt(process.env.AI_MAX_TOKENS) : 250,
                top_p: 1,
                n: parseInt(aiUser.lastName),
                frequency_penalty: 0,
                presence_penalty: 0.6,
            }

            console.log(payload.prompt);

            try {
                const response = await openai.createCompletion(payload)

                generatingResponse = false;

                response.data.choices.forEach(r => {
                    const data = r.text?.toString().trim()
                    if (data) {
                        addMessage(data, aiUser.id, aiUser.id, activeConversation.id, MessageDirection.Incoming);
                    }
                });
            } catch (error) {
                generatingResponse = false;

                const content = "Failed generating response, please try again later"
                addMessage(content, aiUser.id, aiUser.id, activeConversation.id, MessageDirection.Incoming);
            }
        }

    };

    const getTypingIndicator = useCallback(
        () => {

            if (activeConversation) {

                if (generatingResponse) {
                    return <TypingIndicator content={`Generating response`} />
                }

            }

            return undefined;

        }, [activeConversation],
    );


    return (<MainContainer responsive>
        <Sidebar position="left" scrollable>
            <ConversationHeader style={{ backgroundColor: "#fff" }}>
                <Avatar src={user.avatar} />
                <ConversationHeader.Content>
                    {user.username}
                </ConversationHeader.Content>
            </ConversationHeader>
            <ConversationList>
                {conversations.map(c => {
                    // Helper for getting the data of the first participant
                    const [avatar, name, bio] = (() => {

                        const participant = c.participants.length > 0 ? c.participants[0] : undefined;

                        if (participant) {
                            const user = getUser(participant.id);
                            if (user) {

                                return [<Avatar src={user.avatar} />, user.username, user.bio]

                            }
                        }

                        return [undefined, undefined, undefined]
                    })();

                    return <Conversation key={c.id}
                        name={name}
                        info={bio}
                        active={activeConversation?.id === c.id}
                        unreadCnt={c.unreadCounter}
                        onClick={() => putActiveConversation(c.id)}>
                        {avatar}
                    </Conversation>
                })}
            </ConversationList>
        </Sidebar>

        <ChatContainer>
            {activeConversation && <ConversationHeader>
                {aiUserAvatar}
                <ConversationHeader.Content userName={aiUserName} info={aiUserBio} />
            </ConversationHeader>}
            <MessageList typingIndicator={getTypingIndicator()}>
                {activeConversation && currentMessages.map((g) => <MessageGroup key={g.id} direction={g.direction}>
                    <MessageGroup.Messages>
                        {g.messages.map((m: ChatMessage<MessageContentType>) =>
                            <Message key={m.id} model={{
                                type: "html",
                                payload: m.content,
                                direction: m.direction,
                                position: "normal"
                            }} />)}
                    </MessageGroup.Messages>
                    {g.direction === MessageDirection.Incoming ? aiUserAvatar : <Avatar src={user.avatar} />}
                </MessageGroup>)}
            </MessageList>
            <MessageInput value={currentMessage} onChange={handleChange} onSend={handleSend} disabled={!activeConversation} attachButton={false} placeholder="Type here..." autoFocus />
        </ChatContainer>

    </MainContainer>);

}